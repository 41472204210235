import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Card from './card.js';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux'
import { loginFormElementChanged, loginRequestSentToServer, loginUnsuccesfull, loginSuccesfull, loginNeedstwofa } from './reducer.js'

function App() {
  const loginPage = useSelector((state) => state.logic.loginPage)

  const dispatch = useDispatch()

  function attemptLogin() {
    return async function attemptLoginThunk(dispatch, getState) {
      const state = getState();
      let url = "https://accounts.myappbackend.cloud/api/users/token";
      let body = {};

      if (state.logic.loginPage.twofaBox.visible == false) {
        url = 'https://accounts.myappbackend.cloud/api/users/token';
        body = { "username": state.logic.loginPage.emailBox.value, "password": state.logic.loginPage.passwordBox.value };
      }

      if (state.logic.loginPage.twofaBox.visible == true) {
        url = 'https://accounts.myappbackend.cloud/api/users/token/2fa';
        body = { "username": state.logic.loginPage.emailBox.value, "password": state.logic.loginPage.passwordBox.value, "2faOTP": state.logic.loginPage.twofaBox.value };
      }

      dispatch(loginRequestSentToServer());
      
      fetch(url, {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(response => {
          switch (response.status) {
            case "ok":
              dispatch(loginSuccesfull());
              window.location.href = "https://www.myappbackend.cloud/?a=" + response.tempCode + "&s=" + response.signature;
              break;
            case "2fa":
              dispatch(loginNeedstwofa());
              break;
            case "error":
              dispatch(loginUnsuccesfull());
              break;
          }
        }).catch(() => {dispatch(loginUnsuccesfull())})
    }
  }

  return (
    <div style={{ "backgroundColor": "#F3F4F6", "height": "100%", "width": "100%", "display": "flex", "align-items": "center", "justify-content": "center" }}>
      <div style={{ "width": "600px" }}>
        <Card>
          <Stack direction="horizontal" gap={2}>
            <div>
              <Link style={{ "font-family": "Bebas Neue, sans-serif", "fontSize": "40px", "color": "#20bf6b", "textDecoration": "none" }} to={'/'}>ScanFx2</Link>
            </div>
            <div className="ms-auto" >
              <Link style={{ "font-family": "Rubik, sans-serif;", "fontSize": "16px", "color": "#778ca3", "textDecoration": "none" }} to={'/create'}>Create an Account</Link>
            </div>
          </Stack>

          <Stack direction="vertical" style={{ "marginTop": "24px" }} gap={2}>
            <Form.Floating className="mb-3">
              <Form.Control
                id="floatingInputCustom"
                type="email"
                placeholder="name@example.com"
                value={loginPage.emailBox.value}
                onChange={(event) => dispatch(loginFormElementChanged({ "id": "email", "value": event.target.value }))}
              />
              <label htmlFor="floatingInputCustom">Email address</label>
            </Form.Floating>
            <Form.Floating className="mb-3">
              <Form.Control
                id="floatingPasswordCustom"
                type="password"
                placeholder="Password"
                value={loginPage.passwordBox.value}
                onChange={(event) => dispatch(loginFormElementChanged({ "id": "password", "value": event.target.value }))}
              />
              <label htmlFor="floatingPasswordCustom">Password</label>
            </Form.Floating>
            {loginPage.alert.visible == true &&
              <Alert key='danger' variant='danger'>
                {"Login failed, Incorrect email or password. You might have "}
                <Alert.Link to="/forgot">Forgot your password</Alert.Link>
              </Alert>
            }
            {loginPage.twofaBox.visible == true &&
              <Form.Floating>
                <Form.Control
                  id="floatingPasswordCustom"
                  type="text"
                  placeholder="Two-Factor Auth Code"
                  value={loginPage.twofaBox.value}
                  disabled={loginPage.twofaBox.disabled}
                  onChange={(event) => dispatch(loginFormElementChanged({ "id": "twofaBox", "value": event.target.value }))}
                />
                <label htmlFor="floatingPasswordCustom">Two-Factor Auth Code</label>
              </Form.Floating>
            }

            <Button style={{ "marginTop": "20px" }} disabled={loginPage.button.disabled} onClick={() => dispatch(attemptLogin())} size="lg" variant="dark">
              {loginPage.button.loading == true &&
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              }
              {loginPage.button.loading == false &&
                loginPage.button.text
              }
            </Button>

            <div style={{ "marginTop": "24px" }} >
              <Link style={{ "font-family": "Rubik, sans-serif;", "fontSize": "16px", "color": "#fc5c65", "textDecoration": "none" }} to={`/forgot`}>Forgot Password?</Link>
            </div>
          </Stack>
        </Card>
      </div>
    </div>
  );
}

export default App;