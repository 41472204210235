import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Tabs from 'react-bootstrap/Tabs';
import Card from './card.js';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import { emailChanged, nicknameChanged, passwordChanged, rePasswordChanged, termsCheckChanged, createAccountFormElementChanged, createAccountButtonClicked, createAccountSuccesfull, createAccountUnsuccesfull, createAccountAlreadyRegistered } from './reducer.js';

function Createaccountpage() {
  const createAccountPage = useSelector((state) => state.logic.createAccountPage);
  const dispatch = useDispatch()

  function postData() {
    return async function fetchTodoByIdThunk(dispatch, getState) {
      const state = getState()
      if (state.logic.createAccountPage.button.disabled == false) {
        dispatch(createAccountButtonClicked());
        if (state.logic.createAccountPage.passwordBox.value == state.logic.createAccountPage.rePasswordBox.value) {
          fetch('https://accounts.myappbackend.cloud/api/users', {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({ "username": state.logic.createAccountPage.emailBox.value, "password": state.logic.createAccountPage.passwordBox.value, "usernickname": state.logic.createAccountPage.nicknameBox.value })
          })
            .then(response => response.json())
            .then(response => {
              switch (response.status) {
                case "ok":
                  dispatch(createAccountSuccesfull());
                  window.location.href = "/";
                  break;

                case "alreadyRegistered":
                  dispatch(createAccountAlreadyRegistered())
                  break;

                case "error":
                  dispatch(createAccountUnsuccesfull());
                  break;
              }
            }).catch(() => { dispatch(createAccountUnsuccesfull()) })
        }
      }
    }
  }

  return (
    <div style={{ "backgroundColor": "#F3F4F6", "height": "100%", "width": "100%", "display": "flex", "align-items": "center", "justify-content": "center" }}>
      <div style={{ "width": "600px" }}>
        <Card>
          <Stack direction="horizontal" gap={2}>
            <div>
              <Link style={{ "font-family": "Bebas Neue, sans-serif", "fontSize": "40px", "color": "#20bf6b", "textDecoration": "none" }} to={'/'} >ScanFx</Link>
            </div>
          </Stack>

          <div style={{ "marginTop": "12px" }} >
            <a style={{ "font-family": "Rubik, sans-serif;", "fontSize": "20px", "color": "#778ca3" }}>Create a New Account</a>
          </div>

          <Stack direction="vertical" style={{ "marginTop": "24px" }} gap={2}>
            <Form.Floating className="mb-3">
              <Form.Control
                id="floatingInputCustom"
                type="email"
                placeholder="name@example.com"
                value={createAccountPage.emailBox.value}
                isInvalid={createAccountPage.emailBox.invalid}
                disabled={createAccountPage.emailBox.disabled}
                onChange={(event) => dispatch(createAccountFormElementChanged({ "id": "email", "value": event.target.value }))}
              />
              <label htmlFor="floatingInputCustom">Email address</label>
            </Form.Floating>

            {createAccountPage.emailAlert.visible == true &&
              <Alert key='warning' variant='warning'>
                {"Email already registered "}
                <Alert.Link href="/">Try logging in</Alert.Link>
                {" or you "}
                <Alert.Link href="/forgot">Forgot your password</Alert.Link>
              </Alert>
            }

            <Form.Floating className="mb-3">
              <Form.Control
                id="floatingInputCustom"
                type="text"
                placeholder="Tom"
                value={createAccountPage.nicknameBox.value}
                isInvalid={createAccountPage.nicknameBox.invalid}
                disabled={createAccountPage.nicknameBox.disabled}
                onChange={(event) => dispatch(createAccountFormElementChanged({ "id": "nickname", "value": event.target.value }))}
              />
              <label htmlFor="floatingInputCustom">Nickname</label>
            </Form.Floating>

            <Form.Floating>
              <Form.Control
                id="floatingPasswordCustom"
                type="password"
                placeholder="Password"
                value={createAccountPage.passwordBox.value}
                isInvalid={createAccountPage.passwordBox.invalid}
                disabled={createAccountPage.passwordBox.disabled}
                onChange={(event) => dispatch(createAccountFormElementChanged({ "id": "password", "value": event.target.value }))}
              />
              <label htmlFor="floatingPasswordCustom">Password</label>
            </Form.Floating>

            {createAccountPage.passwordRules.visible == true &&
              <div style={{ "marginBottom": "15px" }}>
                <PasswordHint state={createAccountPage.passwordRules.length} symbol="check" text="8-20 characters long" />
                <PasswordHint state={createAccountPage.passwordRules.lettersandnumbers} symbol="check" text="contain letters and numbers" />
                <PasswordHint state={createAccountPage.passwordRules.spaces} symbol="check" text="must not contain spaces, invalid symbols or emoji" />
                <PasswordHint state="empty" symbol="info" text="may contain symbols (! @ # $ % ^ & * ( ) _)" />
              </div>
            }

            <Form.Floating>
              <Form.Control
                id="floatingPasswordCustom"
                type="password"
                placeholder="Password"
                value={createAccountPage.rePasswordBox.value}
                isInvalid={createAccountPage.rePasswordBox.invalid}
                disabled={createAccountPage.rePasswordBox.disabled}
                onChange={(event) => dispatch(createAccountFormElementChanged({ "id": "repassword", "value": event.target.value }))}
              />
              <label htmlFor="floatingPasswordCustom">Re-Type Password</label>
              {createAccountPage.passwordAlert.visible == true &&
                <Form.Text id="passwordHelpBlock" muted>
                  Passwords must match!
                </Form.Text>
              }
            </Form.Floating>



            <Form.Floating style={{ "marginTop": "20px" }}>
              <Form.Check // prettier-ignore
                type='checkbox'
                id='termsandcond'
                label='By signing up, you agree to our Terms of Service & Privacy Policy.'
                checked={createAccountPage.termsCheckBox.checked}
                disabled={createAccountPage.termsCheckBox.disabled}
                onChange={(event) => dispatch(createAccountFormElementChanged({ "id": "termscheckbox", "checked": event.target.checked }))}
              />
            </Form.Floating>

            <Button style={{ "marginTop": "20px" }} size="lg" onClick={() => dispatch(postData())} disabled={createAccountPage.button.disabled} variant="dark">
              {createAccountPage.button.loading == true &&
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              }
              {createAccountPage.button.loading == false &&
                createAccountPage.button.text
              }
            </Button>

            <div style={{ "marginTop": "24px" }} >
              <a style={{ "font-family": "Rubik, sans-serif;", "fontSize": "16px", "color": "#778ca3" }}>Already a User? </a>
              <Link style={{ "font-family": "Rubik, sans-serif;", "fontSize": "16px", "color": "#20bf6b", "textDecoration": "none" }} to={'/'}>Log in Here</Link>
            </div>
          </Stack>
        </Card>
      </div>
    </div>
  );
}

function PasswordHint(props) {
  var color = "green";
  switch (props.state) {
    case "valid":
      color = "#27ae60";
      break;
    case "invalid":
      color = "#DC3545";
      break;
    case "empty":
      color = "black";
      break;
  }

  return (
    <div>
      {props.symbol == "check" &&
      <i class="bi bi-check-circle-fill" style={{ "marginRight": "10px", "color": color }}></i>
      }

      {props.symbol == "info" &&
      <i class="bi bi-info-circle-fill" style={{ "marginRight": "10px", "color": color }}></i>
      }
      
      <a style={{ "color": color }}>{props.text}</a>
    </div>);
}

export default Createaccountpage;