import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Spinner } from 'react-bootstrap';
import Card from './card.js';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { forgotPasswordRequestSentToServer, forgotPasswordFormElementChanged, forgotPasswordGetCodeSuccessfull, forgotPasswordGetCodeUnsuccessfull } from './reducer.js'

function Forgotpassword() {
  const forgotPasswordPage = useSelector((state) => state.logic.forgotPasswordPage)

  const dispatch = useDispatch()

  function postData() {
    return async function fetchTodoByIdThunk(dispatch, getState) {
      const state = getState();
      dispatch(forgotPasswordRequestSentToServer());
      fetch('https://accounts.myappbackend.cloud/api/users/password/resetcode', {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify({ "username": state.logic.forgotPasswordPage.emailBox.value})
      })
        .then(response => response.json())
        .then(response => {
          switch (response.status) {
            case "ok":
              dispatch(forgotPasswordGetCodeSuccessfull());
              window.location.href = "/enterresetcode";
              break;

            case "error":
              dispatch(forgotPasswordGetCodeUnsuccessfull());
              break;
          }
        }).catch(() => { dispatch(forgotPasswordGetCodeUnsuccessfull()) })
    }
  }

  return (
    <div style={{ "backgroundColor": "#F3F4F6", "height": "100%", "width": "100%", "display": "flex", "align-items": "center", "justify-content": "center" }}>
      <div style={{ "width": "600px" }}>

        <Card>
          <Stack direction="horizontal" gap={2}>
            <div>
              <Link style={{ "font-family": "Bebas Neue, sans-serif", "fontSize": "40px", "color": "#20bf6b", "textDecoration": "none" }} to={'/'} >ScanFx</Link>
            </div>
          </Stack>

          <div style={{ "marginTop": "12px" }} >
            <a style={{ "font-family": "Rubik, sans-serif;", "fontSize": "20px", "color": "#778ca3" }}>Forgot your password?</a>
          </div>

          <Stack direction="vertical" style={{ "marginTop": "24px" }} gap={2}>
            <Form.Floating className="mb-3">
              <Form.Control
                id="floatingInputCustom"
                type="email"
                placeholder="name@example.com"
                value={forgotPasswordPage.emailBox.value}
                onChange={(event) => dispatch(forgotPasswordFormElementChanged({"id":"emailBox", "value":event.target.value}))}
              />
              <label htmlFor="floatingInputCustom">Email address</label>
            </Form.Floating>

            <Button size="lg" onClick={() => dispatch(postData())} disabled={forgotPasswordPage.button.disabled} variant="dark">
              {forgotPasswordPage.button.loading == true &&
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              }
              {forgotPasswordPage.button.loading == false &&
                forgotPasswordPage.button.text
              }
            </Button>

            <div style={{ "marginTop": "24px" }} >
              <a style={{ "font-family": "Rubik, sans-serif;", "fontSize": "16px", "color": "#778ca3" }}>Found it again? </a>
              <Link style={{ "font-family": "Rubik, sans-serif;", "fontSize": "16px", "color": "#20bf6b", "textDecoration": "none" }} to={'/'}>Log in Here</Link>
            </div>
          </Stack>
        </Card>
      </div>
    </div>
  );
}



export default Forgotpassword;