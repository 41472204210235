import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from './card.js';
import { Spinner } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';

import "bootstrap-icons/font/bootstrap-icons.css";

import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import {
  enterResetCodeFormElementChanged,
  enterResetCodeFormRequestSentToServer,
  otpCheckSuccesfull,
  otpCheckUnsuccesfull,
  enterResetCodeFormOTPRequestSentToServer,
  passwordChangeSuccesfull,
  passwordChangeUnsuccesfull
} from './reducer.js';

function Enterresetcode() {
  const enterResetCodeForm = useSelector((state) => state.logic.enterResetCodeForm);
  console.log(enterResetCodeForm);
  const dispatch = useDispatch();

  function postData() {
    return async function fetchTodoByIdThunk(dispatch, getState) {
      const state = getState();
      if (state.logic.enterResetCodeForm.resetCode.valid == false && state.logic.enterResetCodeForm.button.disabled == false) {
        dispatch(enterResetCodeFormOTPRequestSentToServer());
        fetch('https://accounts.myappbackend.cloud/api/users/verification/otp', {
          method: 'POST',
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify({ "username": state.logic.enterResetCodeForm.emailBox.value, "OTP": state.logic.enterResetCodeForm.codeBox.value })
        })
          .then(response => response.json())
          .then(response => {
            switch (response.status) {
              case "ok":
                dispatch(otpCheckSuccesfull());
                break;

              case "error":
                dispatch(otpCheckUnsuccesfull());
                break;
            }
          }).catch(() => { dispatch(otpCheckUnsuccesfull()) })
      }

      if (state.logic.enterResetCodeForm.resetCode.checked == true && state.logic.enterResetCodeForm.resetCode.valid == true) {
        dispatch(enterResetCodeFormRequestSentToServer());
        fetch('https://accounts.myappbackend.cloud/api/users/password', {
          method: 'POST',
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify({ "username": state.logic.enterResetCodeForm.emailBox.value, "OTP": state.logic.enterResetCodeForm.codeBox.value, "newPassword": state.logic.enterResetCodeForm.newPasswordBox.value })
        })
          .then(response => response.json())
          .then(response => {
            switch (response.status) {
              case "ok":
                dispatch(passwordChangeSuccesfull());
                window.location.href = "/";
                break;

              case "error":
                dispatch(passwordChangeUnsuccesfull());
                break;
            }
          }).catch(() => { dispatch(passwordChangeUnsuccesfull()) })
      }


    }
  }

  return (
    <div style={{ "backgroundColor": "#F3F4F6", "height": "100%", "width": "100%", "display": "flex", "align-items": "center", "justify-content": "center" }}>
      <div style={{ "width": "600px" }}>
        <Card>
          <Stack direction="horizontal" gap={2}>
            <div>
              <Link style={{ "font-family": "Bebas Neue, sans-serif", "fontSize": "40px", "color": "#20bf6b", "textDecoration": "none" }} to={'/'} >ScanFx</Link>
            </div>
          </Stack>

          <div style={{ "marginTop": "12px" }} >
            <a style={{ "font-family": "Rubik, sans-serif;", "fontSize": "20px", "color": "#778ca3" }}>Enter your email address and the 6 digit reset code we sent to you.</a>
          </div>

          <Stack direction="vertical" style={{ "marginTop": "24px" }} gap={2}>
            {enterResetCodeForm.codeBox.visible == true &&
              <Form.Floating className="mb-3">
                <Form.Control
                  id="floatingInputCustom"
                  type="text"
                  placeholder="name@example.com"
                  value={enterResetCodeForm.emailBox.value}
                  onChange={(event) => dispatch(enterResetCodeFormElementChanged({ "id": "emailBox", "value": event.target.value }))}
                  disabled={enterResetCodeForm.emailBox.disabled}
                />
                <label htmlFor="floatingInputCustom">Email address</label>
              </Form.Floating>
            }

            {enterResetCodeForm.codeBox.visible == true &&
              <Form.Floating className="mb-3">
                <Form.Control
                  id="floatingInputCustom"
                  type="text"
                  placeholder="name@example.com"
                  value={enterResetCodeForm.codeBox.value}
                  isInvalid={enterResetCodeForm.codeBox.invalid}
                  onChange={(event) => dispatch(enterResetCodeFormElementChanged({ "id": "codeBox", "value": event.target.value }))}
                  disabled={enterResetCodeForm.codeBox.disabled}
                />
                <label htmlFor="floatingInputCustom">Reset code</label>
              </Form.Floating>
            }

            {enterResetCodeForm.codeAlert.visible == true &&
              <Alert key='warning' variant='warning'>
                {"The code is 6 digits long and only contains numbers."}
              </Alert>
            }
            {enterResetCodeForm.newPasswordBox.visible == true &&
              <Form.Floating className="">
                <Form.Control
                  id="floatingInputCustom"
                  type="password"
                  placeholder="name@example.com"
                  value={enterResetCodeForm.newPasswordBox.value}
                  isInvalid={enterResetCodeForm.newPasswordBox.invalid}
                  onChange={(event) => dispatch(enterResetCodeFormElementChanged({ "id": "newPassword", "value": event.target.value }))}
                  disabled={enterResetCodeForm.newPasswordBox.disabled}
                />
                <label htmlFor="floatingInputCustom">New Password</label>
              </Form.Floating>
            }
            {enterResetCodeForm.passwordRules.visible == true &&
              <div style={{ "marginBottom": "15px" }}>
                <PasswordHint state={enterResetCodeForm.passwordRules.length} symbol="check" text="8-20 characters long" />
                <PasswordHint state={enterResetCodeForm.passwordRules.lettersandnumbers} symbol="check" text="contain letters and numbers" />
                <PasswordHint state={enterResetCodeForm.passwordRules.spaces} symbol="check" text="must not contain spaces, invalid symbols or emoji" />
                <PasswordHint state="empty" symbol="info" text="may contain symbols (! @ # $ % ^ & * ( ) _)" />
              </div>
            }

            {enterResetCodeForm.rePasswordBox.visible == true &&
              <Form.Floating className="">
                <Form.Control
                  id="floatingInputCustom"
                  type="password"
                  placeholder="name@example.com"
                  value={enterResetCodeForm.rePasswordBox.value}
                  isInvalid={enterResetCodeForm.rePasswordBox.invalid}
                  onChange={(event) => dispatch(enterResetCodeFormElementChanged({ "id": "reNewPassword", "value": event.target.value }))}
                  disabled={enterResetCodeForm.rePasswordBox.disabled}
                />
                <label htmlFor="floatingInputCustom">Re-Type Password</label>
              </Form.Floating>
            }

            <Button style={{ "marginTop": "20px" }} size="lg" onClick={() => dispatch(postData())} disabled={enterResetCodeForm.button.disabled} variant="dark">
              {enterResetCodeForm.button.loading == true &&
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              }
              {enterResetCodeForm.button.loading == false &&
                enterResetCodeForm.button.text
              }
            </Button>
          </Stack>
        </Card>
      </div>
    </div>
  );
}

function PasswordHint(props) {
  var color = "green";
  switch (props.state) {
    case "valid":
      color = "#27ae60";
      break;
    case "invalid":
      color = "#DC3545";
      break;
    case "empty":
      color = "black";
      break;
  }

  return (
    <div>
      {props.symbol == "check" &&
        <i class="bi bi-check-circle-fill" style={{ "marginRight": "10px", "color": color }}></i>
      }

      {props.symbol == "info" &&
        <i class="bi bi-info-circle-fill" style={{ "marginRight": "10px", "color": color }}></i>
      }

      <a style={{ "color": color }}>{props.text}</a>
    </div>);
}

export default Enterresetcode;