import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loginPage: {
        emailBox: {
            visible: true,
            value: "",
            disabled: false,
            invalid: false,
        },

        passwordBox: {
            visible: true,
            value: "",
            disabled: false,
            invalid: false,
        },

        alert: {
            visible: false,
            text: "Login error, Username and/or password are incorrect.",
        },

        twofaBox: {
            visible: false,
            value: "",
            disabled: true,
            invalid: false,
        },

        button: {
            visible: true,
            text: "Login",
            disabled: true,
            loading: false
        },
    },

    createAccountPage: {
        button: {
            visible: true,
            text: "Create",
            disabled: true,
            loading: false
        },

        emailBox: {
            visible: true,
            value: "",
            disabled: false,
            invalid: false,
        },

        emailAlert: {
            visible: false,
            text: "Email already registered.",
        },

        nicknameBox: {
            visible: true,
            value: "",
            disabled: false,
            invalid: false,
        },

        passwordBox: {
            visible: true,
            value: "",
            disabled: false,
            invalid: false,
        },

        rePasswordBox: {
            visible: true,
            value: "",
            disabled: false,
            invalid: false,
        },

        passwordAlert: {
            visible: false,
            text: "Passwords must match!",
        },

        termsCheckBox: {
            visible: true,
            checked: false,
            disabled: false,
            invalid: false,
        },

        passwordRules:{
            length: "empty",
            lettersandnumbers: "empty",
            spaces: "empty",
            visible: true,
        }
    },

    forgotPasswordPage: {
        button: {
            visible: true,
            text: "Get Code",
            disabled: true,
            loading: false
        },

        emailBox: {
            visible: true,
            value: "",
            disabled: false,
            invalid: false,
        },

        emailAlert: {
            visible: false,
            text: "Email already registered.",
        },
    },

    enterResetCodeForm: {
        button: {
            visible: true,
            text: "Verify OTP",
            disabled: true,
            loading: false
        },

        emailBox: {
            visible: true,
            value: "",
            disabled: false,
            invalid: false,
        },

        codeBox: {
            visible: true,
            value: "",
            disabled: false,
            invalid: false,
        },

        codeAlert: {
            visible: false,
        },

        newPasswordBox: {
            visible: false,
            value: "",
            disabled: false,
            invalid: false,
        },

        rePasswordBox: {
            visible: false,
            value: "",
            disabled: false,
            invalid: false,
        },

        emailAlert: {
            visible: false,
            text: "Email already registered.",
        },

        resetCode: {
            code: "",
            valid: false,
            checked: false,
            requestPending: false,
        },

        passwordRules:{
            length: "valid",
            lettersandnumbers: "valid",
            spaces: "valid",
            visible: false,
        }

    }
}

export const logicSlice = createSlice({
    name: 'logic',
    initialState,
    reducers: {
        loginFormElementChanged: (state, action) => {
            switch (action.payload.id) {
                case "email":
                    state.loginPage.emailBox.value = action.payload.value;
                    state.createAccountPage.emailBox.value = action.payload.value;
                    state.forgotPasswordPage.emailBox.value = action.payload.value;
                    break;

                case "password":
                    state.loginPage.passwordBox.value = action.payload.value;
                    break;

                case "twofaBox":
                    state.loginPage.twofaBox.value = action.payload.value;
                    break;
            }

            let email = state.loginPage.emailBox.value;
            let password = state.loginPage.passwordBox.value;

            if (String(email).length >= 3 && String(password).length >= 3) {
                state.loginPage.button.disabled = false;
            } else {
                state.loginPage.button.disabled = true;
            }
        },

        loginRequestSentToServer: (state) => {
            state.loginPage.alert.visible = false;
            state.loginPage.button.loading = true;
            state.loginPage.button.disabled = true;
            state.loginPage.emailBox.disabled = true;
            state.loginPage.passwordBox.disabled = true;
            state.loginPage.twofaBox.disabled = true;
        },

        loginUnsuccesfull: (state) => {
            state.loginPage.alert.visible = true;
            state.loginPage.button.loading = false;
            state.loginPage.button.disabled = false;
            state.loginPage.emailBox.disabled = false;
            state.loginPage.passwordBox.disabled = false;
            state.loginPage.twofaBox.disabled = false;
            state.loginPage.twofaBox.value = "";
            state.loginPage.twofaBox.visible = false;
        },

        loginNeedstwofa: (state) => {
            state.loginPage.alert.visible = false;
            state.loginPage.button.loading = false;
            state.loginPage.button.disabled = false;
            state.loginPage.twofaBox.disabled = false;
            state.loginPage.twofaBox.visible = true;
            
        },

        loginSuccesfull: (state) => {
            state.loginPage.emailBox.value = "";
            state.loginPage.passwordBox.value = "";

            state.loginPage.alert.visible = false;
            state.loginPage.button.loading = false;
            state.loginPage.button.disabled = false;

            state.loginPage.twofaBox.visible = false;
            state.loginPage.twofaBox.disabled = false;
            state.loginPage.twofaBox.value = "";
        },


        createAccountButtonClicked: (state) => {
            let password = state.createAccountPage.passwordBox.value;
            let rePassword = state.createAccountPage.rePasswordBox.value;
            if (password == rePassword) {
                state.createAccountPage.button.disabled = true;
                state.createAccountPage.button.loading = true;
                state.createAccountPage.emailBox.disabled = true;
                state.createAccountPage.nicknameBox.disabled = true;
                state.createAccountPage.passwordBox.disabled = true;
                state.createAccountPage.rePasswordBox.disabled = true;
                state.createAccountPage.emailAlert.visible = false;

                state.createAccountPage.passwordBox.invalid = false;
                state.createAccountPage.rePasswordBox.invalid = false;
                state.createAccountPage.passwordAlert.visible = false;
            } else {
                state.createAccountPage.button.disabled = false;
                state.createAccountPage.button.loading = false;
                state.createAccountPage.emailBox.disabled = false;
                state.createAccountPage.nicknameBox.disabled = false;
                state.createAccountPage.passwordBox.disabled = false;
                state.createAccountPage.passwordBox.invalid = true;
                state.createAccountPage.rePasswordBox.invalid = true;
                state.createAccountPage.passwordAlert.visible = true;
                state.createAccountPage.rePasswordBox.disabled = false;
                state.createAccountPage.emailAlert.visible = false;
            }
        },

        createAccountFormElementChanged: (state, action) => {
            switch (action.payload.id) {
                case "email":
                    state.createAccountPage.emailBox.value = action.payload.value;
                    state.loginPage.emailBox.value = action.payload.value;
                    state.forgotPasswordPage.emailBox.value = action.payload.value;
                    break;

                case "nickname":
                    state.createAccountPage.nicknameBox.value = action.payload.value;
                    break;

                case "password":
                    state.createAccountPage.passwordBox.value = action.payload.value;
                    break;

                case "repassword":
                    state.createAccountPage.rePasswordBox.value = action.payload.value;
                    break;

                case "termscheckbox":
                    state.createAccountPage.termsCheckBox.checked = action.payload.checked;
                    break;
            }

            let email = state.createAccountPage.emailBox.value;
            let nickname = state.createAccountPage.nicknameBox.value;
            let password = state.createAccountPage.passwordBox.value;
            let rePassword = state.createAccountPage.rePasswordBox.value;
            let terms = state.createAccountPage.termsCheckBox.checked;

            if (String(email).length >= 3 && String(nickname).length >= 3 && String(password).length >= 3 && String(rePassword).length >= 3) {
                if (terms == true) {
                    state.createAccountPage.button.disabled = false;
                } else {
                    state.createAccountPage.button.disabled = true;
                }
            } else {
                state.createAccountPage.button.disabled = true;
            }

            const form = state.createAccountPage;

            form.passwordRules.visible = true;
            form.rePasswordBox.invalid = false;
            if (String(password).length == 0){
                form.passwordRules.length = "empty";
                form.passwordRules.lettersandnumbers = "empty";
                form.passwordRules.spaces = "empty";
            } else {
                if (String(password).length >= 8 && String(password).length <= 20){
                    form.passwordRules.length = "valid";
                } else {
                    form.passwordRules.length = "invalid";
                }
    
                if(String(password).match("[^A-Za-z]+") != null && String(password).match("[^0-9]+") != null){
                    form.passwordRules.lettersandnumbers = "valid";
                } else {
                    form.passwordRules.lettersandnumbers = "invalid";
                }
    
                if(String(password).match("^([a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\-])*$") != null){
                    form.passwordRules.spaces = "valid";
                } else {
                    form.passwordRules.spaces = "invalid";
                }
            }
        
            if(passwordsMatchUpToNow(form.passwordBox.value, form.rePasswordBox.value) == false){
                form.rePasswordBox.invalid = true;
            }
    
            if(password == rePassword && form.passwordRules.length == "valid" && form.passwordRules.lettersandnumbers == "valid" && form.passwordRules.spaces == "valid" && String(form.emailBox.value).length >= 3 && String(form.nicknameBox.value).length >= 3 && form.termsCheckBox.checked == true){
                form.button.disabled = false;
            } else {
                form.button.disabled = true;
            }
        },

        createAccountSuccesfull: (state) => {
            state.createAccountPage.emailAlert.value = "";
            state.createAccountPage.emailAlert.text = "Email already registered.";

            state.createAccountPage.nicknameBox.value = "";
            state.createAccountPage.passwordBox.value = "";
            state.createAccountPage.rePasswordBox.value = "";
            state.createAccountPage.termsCheckBox.checked = false;
        },

        createAccountUnsuccesfull: (state) => {

        },

        createAccountAlreadyRegistered: (state) => {
            state.createAccountPage.emailAlert.visible = true;
            state.createAccountPage.emailAlert.text = "Email already registered.";

            state.createAccountPage.nicknameBox.value = "";
            state.createAccountPage.passwordBox.value = "";
            state.createAccountPage.rePasswordBox.value = "";
            state.createAccountPage.termsCheckBox.checked = false;

            state.createAccountPage.button.disabled = false;
            state.createAccountPage.button.loading = false;

            state.createAccountPage.passwordBox.invalid = false;
            state.createAccountPage.rePasswordBox.invalid = false;
            state.createAccountPage.passwordAlert.visible = false;
        },



        forgotPasswordRequestSentToServer: (state) => {
            state.forgotPasswordPage.button.disabled = true;
            state.forgotPasswordPage.button.loading = true;
            state.forgotPasswordPage.emailBox.disabled = true;
        },

        forgotPasswordFormElementChanged: (state, action) => {
            switch (action.payload.id) {
                case "emailBox":
                    state.forgotPasswordPage.emailBox.value = action.payload.value;
                    state.enterResetCodeForm.emailBox.value = action.payload.value;
                    break;
            }

            const email = state.forgotPasswordPage.emailBox.value;
            if(String(email).length >= 3){
                state.forgotPasswordPage.button.disabled = false;
            } else {
                state.forgotPasswordPage.button.disabled = true;
            }
        },

        forgotPasswordGetCodeSuccessfull: (state) => {
            state.forgotPasswordPage.button.disabled = true;
            state.forgotPasswordPage.button.loading = false;
            state.forgotPasswordPage.emailBox.disabled = false;
            state.forgotPasswordPage.emailBox.value = "";
        },

        forgotPasswordGetCodeUnsuccessfull: (state) => {
            state.forgotPasswordPage.button.disabled = true;
            state.forgotPasswordPage.button.loading = false;
            state.forgotPasswordPage.emailBox.disabled = false;
            state.forgotPasswordPage.emailBox.value = "";
        },



        enterResetCodeFormElementChanged: (state, action) => {
            switch (action.payload.id) {
                case "emailBox":
                    state.enterResetCodeForm.emailBox.value = action.payload.value;
                    break;
                case "codeBox":
                    state.enterResetCodeForm.codeBox.value = action.payload.value;
                    break;
                case "newPassword":
                    state.enterResetCodeForm.newPasswordBox.value = action.payload.value;
                    break;
                case "reNewPassword":
                    state.enterResetCodeForm.rePasswordBox.value = action.payload.value;
                    break;
            }
            reduceEnterResetCode(state);
        },

        enterResetCodeFormOTPRequestSentToServer: (state) => {
            state.enterResetCodeForm.codeAlert.visible = false;
            state.enterResetCodeForm.button.disabled = true;
            state.enterResetCodeForm.button.loading = true;
        },

        enterResetCodeFormOTPIsValid: (state, action) => {
            state.enterResetCodeForm.button.disabled = false;
            state.enterResetCodeForm.button.loading = false;
            state.enterResetCodeForm.button.text = "Change";
        },

        enterResetCodeFormRequestSentToServer: (state) => {
            state.enterResetCodeForm.resetCode.requestPending = true;
            reduceEnterResetCode(state);
        },

        otpCheckSuccesfull: (state) => {
            state.enterResetCodeForm.resetCode.checked = true;
            state.enterResetCodeForm.resetCode.valid = true;
            reduceEnterResetCode(state);
        },

        otpCheckUnsuccesfull: (state) => {
            state.enterResetCodeForm.resetCode.checked = true;
            state.enterResetCodeForm.resetCode.valid = false;
            reduceEnterResetCode(state);
        },

        passwordChangeSuccesfull: (state) => {
            const resetCode = state.enterResetCodeForm.resetCode;
            const form = state.enterResetCodeForm;
        
            form.button.visible = true;
            form.button.loading = false;
            form.button.disabled = true;
            form.resetCode.requestPending = false;
            resetCode.valid = false;
            reduceEnterResetCode(state);
        },

        passwordChangeUnsuccesfull: (state) => {
            const resetCode = state.enterResetCodeForm.resetCode;
            const form = state.enterResetCodeForm;
            form.resetCode.requestPending = false;
            form.button.visible = true;
            form.button.loading = false;
            form.button.disabled = false;

            form.newPasswordBox.value = "";
            form.rePasswordBox.value = "";
        
            resetCode.valid = false;
            reduceEnterResetCode(state);
        }
    },
})

const reduce = (state) => {
    if (String(state.email).length >= 3 && state.password.length >= 3) {
        state.loginButtonDisabled = false;
    } else {
        state.loginButtonDisabled = true;
    }

    if (String(state.email).length >= 3) {
        state.forgotEmailGetCodeButtonDisabled = false;
    } else {
        state.forgotEmailGetCodeButtonDisabled = true;
    }

    if (state.passwordResetButtonText == "Verify") {
        if (String(state.passwordResetCodeBoxValue).length == 6) {
            state.passwordResetButtonDisabled = false;
        } else {
            state.passwordResetButtonDisabled = true;
        }
    }

    if (state.passwordResetButtonText == "Change") {
        if (String(state.passwordResetNewPasswordBoxValue).length >= 6) {
            if (state.passwordResetNewPasswordBoxValue == state.passwordResetReNewPasswordBoxValue) {
                state.passwordResetButtonDisabled = false;
            } else {
                state.passwordResetButtonDisabled = true;
            }
        } else {
            state.passwordResetButtonDisabled = true;
        }
    }
}

const errorInOTPCode = (code) => {
    try {
        if (String(code).length >= 1) {
            let digitsOnly = false;
            let validLength = false;

            if (code.match(/^[0-9]+$/) != null) {
                digitsOnly = true;
            }

            if (String(code).length >= 1 && String(code).length <= 6) {
                validLength = true;
            }

            if (digitsOnly && validLength) {
                return (false)
            } else {
                return (true)
            }
        }
        return (false);
    } catch {
        return (true);
    }
}

const OTPIsCorrectLength = (code) => {
    try {
        if (String(code).length == 6) {
            return (true);
        }
        return (false);
    } catch {
        return (false);
    }
}

const emailValid = (email) => {
    try {
        if (String(email).length >= 3) {
            return (true);
        }
        return (false);
    } catch {
        return (false);
    }
}

const passwordsMatchUpToNow = (password, retyped) => {
    if (String(password).length == 0) {
        return(true);
    }

    if (String(password).length >= 1 && String(retyped).length == 0) {
        return(true);
    }

    if(String(retyped).length > String(password).length){
        return(false);
    }

    if (String(password).startsWith(String(retyped)) == true){
        return(true);
    }

    return(false);
}

const reduceEnterResetCode = (state) => {
    const resetCode = state.enterResetCodeForm.resetCode;
    const form = state.enterResetCodeForm;

    form.button.visible = true;
    form.button.loading = false;
    form.button.disabled = true;

    if (resetCode.valid == false) {
        form.emailBox.visible = true;
        form.emailBox.disabled = false;
        form.emailBox.invalid = false;

        form.button.visible = true;
        form.button.loading = false;
        form.button.text = "Verify OTP";

        form.codeBox.visible = true;
        form.codeBox.disabled = false;
        form.button.disabled = true;

        form.newPasswordBox.visible = false;
        form.newPasswordBox.disabled = false;
        form.newPasswordBox.invalid = false;

        form.rePasswordBox.visible = false;
        form.rePasswordBox.disabled = false;
        form.rePasswordBox.invalid = false;

        form.passwordRules.visible = false;

        if (errorInOTPCode(form.codeBox.value) == true) {
            form.codeBox.invalid = true;
        } else {
            form.codeBox.invalid = false;
        }

        if(OTPIsCorrectLength(form.codeBox.value) == true && errorInOTPCode(form.codeBox.value) == false && emailValid(form.emailBox.value) == true){
            form.button.disabled = false;
        }
    }

    if (resetCode.checked == true && resetCode.valid == true) {
        form.emailBox.visible = true;
        form.emailBox.disabled = true;
        form.emailBox.invalid = false;

        form.codeBox.visible = true;
        form.codeBox.disabled = true;
        form.codeBox.invalid = false;

        form.newPasswordBox.visible = true;
        form.newPasswordBox.disabled = false;
        form.newPasswordBox.invalid = false;

        form.rePasswordBox.visible = true;
        form.rePasswordBox.disabled = false;
        form.rePasswordBox.invalid = false;

        form.button.visible = true;
        form.button.loading = false;
        form.button.disabled = true;
        form.button.text = "Save Password";

        const password = form.newPasswordBox.value;
        const repassword = form.rePasswordBox.value;

        form.passwordRules.visible = true;
        
        if (String(password).length == 0){
            form.passwordRules.length = "empty";
            form.passwordRules.lettersandnumbers = "empty";
            form.passwordRules.spaces = "empty";
        } else {
            if (String(password).length >= 8 && String(password).length <= 20){
                form.passwordRules.length = "valid";
            } else {
                form.passwordRules.length = "invalid";
            }

            if(String(password).match("[^A-Za-z]+") != null && String(password).match("[^0-9]+") != null){
                form.passwordRules.lettersandnumbers = "valid";
            } else {
                form.passwordRules.lettersandnumbers = "invalid";
            }

            if(String(password).match("^([a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\-])*$") != null){
                form.passwordRules.spaces = "valid";
            } else {
                form.passwordRules.spaces = "invalid";
            }
        }
    
        if(passwordsMatchUpToNow(password, repassword) == false){
            form.rePasswordBox.invalid = true;
        }

        if(form.passwordRules.length == "valid" && form.passwordRules.lettersandnumbers == "valid" && form.passwordRules.spaces == "valid" && String(password) == String(repassword)){
            form.button.disabled = false;
        } else {
            form.button.disabled = true;
        }
    }

    if(resetCode.requestPending == true){
        form.button.visible = true;
        form.button.loading = true;
        form.button.disabled = true;

        form.emailBox.disabled = true;
        form.codeBox.disabled = true;
        form.newPasswordBox.disabled = true;
        form.rePasswordBox.disabled = true;
    }
}

export const {
    loginFormElementChanged,
    loginRequestSentToServer,
    loginUnsuccesfull,
    loginSuccesfull,
    loginNeedstwofa,
    createAccountFormElementChanged,
    createAccountButtonClicked,
    createAccountSuccesfull,
    createAccountUnsuccesfull,
    createAccountAlreadyRegistered,
    forgotPasswordRequestSentToServer,
    forgotPasswordFormElementChanged,
    forgotPasswordGetCodeSuccessfull,
    forgotPasswordGetCodeUnsuccessfull,
    enterResetCodeFormElementChanged,
    enterResetCodeFormRequestSentToServer,
    enterResetCodeFormOTPRequestSentToServer,
    otpCheckSuccesfull,
    otpCheckUnsuccesfull,
    passwordChangeSuccesfull,
    passwordChangeUnsuccesfull

} = logicSlice.actions

export default logicSlice.reducer